// @unocss-include
import type { LocaleObject } from '@nuxtjs/i18n'
import type { CountryFragment, PaymentMethodFragment, ShippingMethodFragment } from '#graphql-operations'
import type { StoreState } from '~/composables/store'

export function fetchAvailableCountries() {
  const state = useStore()

  return reactiveLoad<CountryFragment[] | undefined | null>(
    () => state.value.availableCountries,
    (availableCountries) => {
      state.value.availableCountries = availableCountries
    },
    () => useGraphqlQuery('availableCountries').then(result => result.data.availableCountries),
  )
}

export function useAvailableCountries() {
  const state = useStore()
  return computed<CountryFragment[] | undefined | null>(() => state.value.availableCountries)
}

function getEligibleShippingMethods(state: StoreState) {
  if (state.eligibleShippingMethods?.length)
    return state.eligibleShippingMethods

  return undefined
}

export function fetchEligibleShippingMethods() {
  const state = useStore()

  if (!state.value.activeOrder)
    return Promise.resolve(null)

  return reactiveLoad<ShippingMethodFragment[] | undefined | null>(
    () => getEligibleShippingMethods(state.value),
    (eligibleShippingMethods) => {
      state.value.eligibleShippingMethods = eligibleShippingMethods
    },
    () => useGraphqlQuery('eligibleShippingMethods').then(result => result.data.eligibleShippingMethods),
  )
}

export function useEligibleShippingMethods() {
  const state = useStore()
  return computed<ShippingMethodFragment[] | undefined | null>(() => state.value.eligibleShippingMethods)
}

function getEligiblePaymentMethods(state: StoreState) {
  if (state.eligiblePaymentMethods?.length)
    return state.eligiblePaymentMethods

  return undefined
}

export function fetchEligiblePaymentMethods() {
  const state = useStore()

  if (!state.value.activeOrder)
    return Promise.resolve(null)

  return reactiveLoad<PaymentMethodFragment[] | undefined | null>(
    () => getEligiblePaymentMethods(state.value),
    (eligiblePaymentMethods) => {
      state.value.eligiblePaymentMethods = eligiblePaymentMethods?.map(method => ({
        ...method,
        icon: method.code?.includes('bank') ? 'heroicons:building-library-solid' : 'heroicons:credit-card-solid',
        ...(method.code?.indexOf('stripe') === 0 ? { stripe: 'card' } : {}),
      }))
    },
    () => useGraphqlQuery('eligiblePaymentMethods').then(result => result.data.eligiblePaymentMethods.filter(method => method.isEligible)),
  )
}

export function useEligiblePaymentMethods() {
  const state = useStore()
  return computed<PaymentMethodFragment[] | undefined | null>(() => state.value.eligiblePaymentMethods)
}

export function fetchStripePaymentIntent() {
  const state = useStore()

  return reactiveLoad<string | undefined | null>(
    () => state.value.stripePaymentIntent,
    (stripePaymentIntent) => {
      state.value.stripePaymentIntent = stripePaymentIntent
    },
    () => useGraphqlMutation('createStripePaymentIntent').then(result => result.data.createStripePaymentIntent),
  )
}

export function useInitialCheckout() {
  const { activeOrder } = useActiveOrder()
  const { locale, locales } = useI18n()

  const initialCustomer = computed(() => activeOrder.value?.customer)
  const initialShippingAddress = computed(() => activeOrder.value?.shippingAddress)

  const initialCountryCode = computed(() => initialShippingAddress.value?.countryCode
    ? initialShippingAddress.value.countryCode
    : (locales.value as LocaleObject[]).find(localeObject => localeObject.code === locale.value)?.iso!.split('-')[1].toUpperCase(),
  )

  return { initialCustomer, initialShippingAddress, initialCountryCode }
}
